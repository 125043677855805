@font-face {
    font-family: 'TeXGyreAdventor';
    /*noinspection CssUnknownTarget*/
    src: url('fonts/TeXGyreAdventorBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TeXGyreAdventor';
    /*noinspection CssUnknownTarget*/
    src: url('fonts/TeXGyreAdventorBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TeXGyreAdventor';
    /*noinspection CssUnknownTarget*/
    src: url('fonts/TeXGyreAdventorItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TeXGyreAdventor';
    /*noinspection CssUnknownTarget*/
    src: url('fonts/TeXGyreAdventorRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
